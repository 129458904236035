// LanguageSwitcher.js
import React from 'react';

const LanguageSwitcher = ({ currentLanguage, onLanguageChange }) => {
  return (
    <div className="flex space-x-2">
      <button onClick={() => onLanguageChange('en')} className={`w-8 h-8 rounded-full overflow-hidden ${currentLanguage === 'en' ? 'ring-2 ring-yellow-400' : ''}`}>
        <img src="/images/flag-en.png" alt="English" className="w-full h-full object-cover" />
      </button>
      <button onClick={() => onLanguageChange('zh')} className={`w-8 h-8 rounded-full overflow-hidden ${currentLanguage === 'zh' ? 'ring-2 ring-yellow-400' : ''}`}>
        <img src="/images/flag-zh.png" alt="中文" className="w-full h-full object-cover" />
      </button>
    </div>
  );
};

export default LanguageSwitcher;