import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './LandingPage';
import BlogPage from './BlogPage';
import BlogPostPage from './BlogPostPage';

function App() {
  const [language, setLanguage] = useState('en');

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage language={language} setLanguage={handleLanguageChange} />} />
        <Route path="/blog" element={<BlogPage language={language} setLanguage={handleLanguageChange} />} />
        <Route path="/blog/:slug" element={<BlogPostPage language={language} setLanguage={handleLanguageChange} />} />
      </Routes>
    </Router>
  );
}

export default App;