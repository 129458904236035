import React from 'react';
import { Link } from 'react-router-dom';
import blogPosts from './blogPosts';
import LanguageSwitcher from './LanguageSwitcher';

const BlogPage = ({ language, setLanguage }) => {
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold">{language === 'en' ? 'Blog' : '博客'}</h1>
        <LanguageSwitcher currentLanguage={language} onLanguageChange={setLanguage} />
      </div>
      {blogPosts.map((post) => (
        <div key={post.id} className="mb-8">
          <h2 className="text-2xl font-semibold mb-2">{post[language].title}</h2>
          <p className="mb-4">{post[language].excerpt}</p>
          <Link to={`/blog/${post[language].slug}`} className="text-blue-600 hover:text-blue-800">
            {language === 'en' ? 'Read More' : '阅读更多'}
          </Link>
        </div>
      ))}
    </div>
  );
};

export default BlogPage;