import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import blogPosts from './blogPosts';
import LanguageSwitcher from './LanguageSwitcher';

const BlogPostPage = ({ language, setLanguage }) => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [currentPost, setCurrentPost] = useState(null);

  console.log('Rendering BlogPostPage. Current language:', language);
  console.log('Current slug:', slug);

  useEffect(() => {
    console.log('Searching for post with slug:', slug);
    const foundPost = blogPosts.find(post => 
      post.en.slug === slug || post.zh.slug === slug
    );
    if (foundPost) {
      console.log('Found post:', foundPost);
      setCurrentPost(foundPost);
      // Determine the correct language based on the slug
      const newLanguage = foundPost.zh.slug === slug ? 'zh' : 'en';
      setLanguage(newLanguage);
      setPost(foundPost[newLanguage]);
    } else {
      console.log('Post not found for slug:', slug);
    }
  }, [slug, setLanguage]);

  useEffect(() => {
    console.log('Language changed. New language:', language);
    if (currentPost) {
      console.log('Updating post for language:', language);
      setPost(currentPost[language]);
      const newSlug = currentPost[language].slug;
      console.log('New slug:', newSlug);
      navigate(`/blog/${newSlug}`, { replace: true });
    }
  }, [language, currentPost, navigate]);

  if (!post) {
    console.log('Post is null, rendering not found message');
    return <div className="container mx-auto px-4 py-8">Blog post not found</div>;
  }

  const handleLanguageChange = (newLanguage) => {
    console.log('Language change requested to:', newLanguage);
    setLanguage(newLanguage);
  };

  console.log('Rendering post content. Title:', post.title);

  return (
    <div className="container mx-auto px-4 py-8">
      <Helmet>
        <title>{post.title}</title>
        <meta name="description" content={post.excerpt} />
      </Helmet>
      <div className="flex justify-between items-center mb-8">
        <Link to="/blog" className="text-blue-600 hover:text-blue-800">
          {language === 'en' ? '← Back to Blog' : '← 返回博客'}
        </Link>
        <LanguageSwitcher currentLanguage={language} onLanguageChange={handleLanguageChange} />
      </div>
      <h1 className="text-3xl font-bold mb-4">{post.title}</h1>
      <div dangerouslySetInnerHTML={{ __html: post.content }} />
    </div>
  );
};

export default BlogPostPage;